import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { WalletSelectorContextProvider } from './contexts/WalletSelectorContext';

import TopNavBar from './components/TopNavBar';
import HomePage from './pages/HomePage';
import Mint from './pages/Mint';
import Burn from './pages/Burn';


function App() {
	return (
		<BrowserRouter className='App'>
			<WalletSelectorContextProvider>
				<div className='App'>
					<TopNavBar />
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/burn" element={<Burn />} />
						<Route path="/mint" element={<Mint />} />
					</Routes>
				</div>
			</WalletSelectorContextProvider>
		</BrowserRouter>
	);
}

export default App;
