import { Button } from '@mui/material';
import * as nearAPI from 'near-api-js';
import { NEAR_CONFIG } from '../constants';
import { useState, useEffect } from 'react';

import Burn from '../assets/burn_img.JPG'
import Mint from '../assets/mint_img.JPG'
import HomeBg from '../assets/modal_bg.png'
import BurnBtn from '../assets/burn_btn.png'
import GoldCap from '../assets/goldcap.png'
import MintBtn from '../assets/mint_btn.png'
import MintedBg from '../assets/minted_bg.png'
import LeftVector from '../assets/left_vector.png'
import RightVector from '../assets/right_vector.png'
import { Link } from 'react-router-dom';
window.Buffer = window.Buffer || require("buffer").Buffer;  // To fix buffer problem



export default function HomePage() {

	// Retrieve smart contract & account details 
	const near = new nearAPI.Near(NEAR_CONFIG);
	const [metadata, setMetadata] = useState(null)
	let smartContractID = NEAR_CONFIG.nfcContract;
	const contractAccount = new nearAPI.Account(near.connection, smartContractID);
	useEffect(() => {
		async function getMeta() {
			const response = await contractAccount.viewFunction(smartContractID, 'get_metadata');
			console.log(response)
			setMetadata(response);
		}
		getMeta();
	}, []);

	return (
		<div className="page">
			<div id='homeBgSection'>
				<img id='homeBg' src={HomeBg} />
				<div id='homeBgContent'>
					<h1> NEAR FUTURE <span> Classic </span> </h1>
                    <div id='homeNotice'>
                        <p> Mint your Classic Nearbot NFT in 2 ways </p> 
                        <div>
                            <b> 👉 Burn a Primal Nearbot NFT to evolve them into a Classic Nearbot </b> 
                            <b> 👉 Mint a NFC using your Bottle CAPZ NFT for 10N or 7N </b>    
                        </div>
                    </div>
					<div id='homeCenter'>
						<img src={LeftVector} className='vector' />
						<div id='homeOptionSection'>
							<Link to='/burn' className='home-option'>
								<img src={Burn} />
								<div className='home-button b'>
									<img src={BurnBtn} />
									<p> Burn a Near Future: Primal </p>
								</div>
							</Link>
							<Link to='/mint' className='home-option'>
								<img src={Mint} />
								<div className='home-button w'>
									<img src={MintBtn} />
									<p> Mint a Near Future: Classic </p>
								</div>
							</Link>
						</div>
						<img src={RightVector} className='vector' />
                       
					</div>
                    <div id='burnBgContent'>
                            <p> <img src={GoldCap} />Golden CAPZ Mints have a 25% Chance of a FREE Mint!</p>
                        </div>
				</div>
			</div>
			<div id='mintedSection'>
				<div id='mintedBox'>
					<img src={MintedBg} />
					<div id='mintedBoxContent'>
						<h2> {metadata?.tokens_minted || '-'} </h2>
						<p> OUT OF 777 </p>
					</div>
				</div>
				<div id='mintedContent'>
					<h1> NFTS SOLD </h1>
					<p> Near Future is NEAR's only evolving Tribal RPG, built natively into Discord.
						Powered by a Web3 collective, Near Future is a multi-year gaming project with a host of NFT collections on the NEAR blockchain.  </p>
					<a href='https://paras.id/futurenft.near/collections' className='btn'> See Collection </a>
				</div>
			</div>
		</div>
	)
}