import * as nearAPI from 'near-api-js';
import { Share } from 'react-twitter-widgets'
import { useState, useEffect } from 'react';
import { Button, Box, Modal } from '@mui/material';
import { NEAR_CONFIG } from '../constants';
import { useWalletSelector } from '../contexts/WalletSelectorContext';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import BurnBG from '../assets/nft_bg.png'
import Twitter from '../assets/twitter.png'
import Discord from '../assets/discord.png'
import ModalBg from '../assets/modal_bg.png'
import Unreveal from '../assets/unreveal.jpg'
import LeftVector from '../assets/left_vector.png'
import RightVector from '../assets/right_vector.png'
window.Buffer = window.Buffer || require("buffer").Buffer; // To fix buffer problem

export default function Burn() {
	const [nfcToken, setNfcToken] = useState([]);
	const { selector, accountId, modal } = useWalletSelector();
	const BOATLOAD_OF_GAS = nearAPI.utils.format.parseNearAmount("0.0000000003");
	const navigate = useNavigate();

	// Callback modal const
	const [callbackModal, setCallbackModal] = useState(false);
	const [callbackModalMsg, setCallbackModalMsg] = useState(null);
	const handleCallbackModalClose = () => setCallbackModal(false);
	const [txHashedToken, setTxHashedToken] = useState(null);

	const [searchParams, setSearchParams] = useSearchParams();

	// Handle callback function 
	useEffect(() => {
		// Function to check if minted
		async function showModal(txHash) {
			setCallbackModal(true);
			const provider = new nearAPI.providers.JsonRpcProvider(NEAR_CONFIG.jsonRpcURL);
			const result = await provider.txStatus(txHash, accountId ?? 'someone1239574.near');
			const log = result.receipts_outcome[0].outcome.logs[0];
			if (log) {
				const token = log.match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1];
				if (token) { setTxHashedToken(token); }
			}
		}

		// function showErrorMessage(errorMessage) {
		// 	// setCallbackModal(true);
		// 	// setCallbackModalMsg(errorMessage);
		// 	navigate('/burn');
		// }

		async function checkResponse() {
			if (searchParams.get("transactionHashes")) {
				const txHash = searchParams.get("transactionHashes")
				const currentMinted = JSON.parse(localStorage.getItem('minted_tx_hashes'));

				// If current minted is null, it means user never minted anything and got into this part of code, set the array for them
				if (Array.isArray(currentMinted)) {
					// We never displayed modal for this, now display it and add it into "already displayed list"
					if (currentMinted.indexOf(txHash) === -1) {
						localStorage.setItem('minted_tx_hashes', JSON.stringify([...currentMinted, txHash]));
						showModal(txHash)
					}
				}
				else {
					localStorage.setItem('minted_tx_hashes', JSON.stringify([txHash]));
					showModal(txHash)
				}
			}

			if (searchParams.get("errorMessage")) {
				setCallbackModal(true);
				setCallbackModalMsg(searchParams.get('errorMessage'));
				navigate('/burn')
			}
		}
		checkResponse();

	}, [searchParams, accountId]);

	// // Get user NFP
	useEffect(() => {
		async function getAccountState() {
			const near = new nearAPI.Near(NEAR_CONFIG);

			const contractAccount = new nearAPI.Account(near.connection, NEAR_CONFIG.contractName);

			async function getNFT() {
				if (accountId == null) {
				} else {
					const response = await contractAccount.viewFunction(NEAR_CONFIG.nfpContract, 'nft_tokens_for_owner', { "account_id": accountId });
					setNfcToken(response);
				}
			}

			if (selector?.store?.getState()?.contract?.contractId === NEAR_CONFIG.nfpContract && selector.isSignedIn()) {
				getNFT();
			} else {
				setNfcToken([]);
				const wallet = await selector?.wallet()
				wallet.signOut()
			}
		}

		getAccountState()
	}, [selector, accountId]);


	// Burn NFP function 
	const mintByBurningNFP = async (token_id) => {
		const wallet = await selector.wallet();
		return wallet.signAndSendTransaction({
			signerId: accountId,
			actions: [
				{
					type: "FunctionCall",
					params: {
						methodName: "nft_transfer_call",
						args: { receiver_id: NEAR_CONFIG.nfcContract, token_id: token_id, msg: '' },
						gas: BOATLOAD_OF_GAS,
						deposit: 1,
					},
				},
			],
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	return (
		<>
			<div className="mint-page">
				<div id='burnBgSection' style={{ background: "url(" + BurnBG + ")", minHeight: '800px', padding: '4em 2em' }} className='page'>
					<div>
						<div id='burnBgContent'>
							<h1> BURN <br /> NEAR FUTURE <span> PRIMAL </span> </h1>
							<div id='burnItemSection'>
								{
									nfcToken.map((item, index) => (
										<div className='burnItem' key={index}>
											<img src={'https://bafybeiazrw5wem27wql5wojx44yzhoezeh4frkrujdvctrxftv24km7lse.ipfs.dweb.link/' + item.token_id + '.png'} />
											<button onClick={() => mintByBurningNFP(item.token_id)}>Burn Near Future: Primal</button>
										</div>
									))
								}
								{
									selector.isSignedIn() ? null :
										<Box sx={{ p: 5 }}>
											<h4 id='signInText'>Please sign in with your wallet first</h4>
											<Button variant="outlined" id='topBtn' className={accountId ? 'signoutBtn' : ''} sx={{ px: 4, py: 1 }}
												onClick={() => { modal.show() }}
											>
												<h3 style={{ cursor: 'pointer' }}>{accountId ?? null}</h3>
												<label style={{ cursor: 'pointer' }}> {selector.isSignedIn() ? 'Sign Out' : 'Connect Wallet'} </label>
											</Button>
										</Box>
								}
							</div>
						</div>
					</div>
				</div>
				<div id='footer'>
					<h3> NEAR FUTURE <span> CLASSIC </span> </h3>
					<div>
						<a className='top-link' href='https://twitter.com/NearFutureNFT' target="_blank"><img src={Twitter} /> </a>
						<a className='top-link' href='https://discord.gg/6KdKKUuj7Y' target="_blank"> <img src={Discord} /></a>
					</div>
				</div>
			</div>
			<Modal className={callbackModalMsg ? 'callback-modal' : 'callback-modal lg'} open={callbackModal} onClose={handleCallbackModalClose} >
				<Box className='modal-box'>
					<img src={ModalBg} className='modal-bg' />
					<div style={{ textAlign: "center" }}>
						{callbackModalMsg ?
							<p className="font20">{decodeURIComponent(callbackModalMsg)} </p>
							:
							<>
								<h1> Here’s your <br /> Near Future <span> Classic </span> </h1>
								<div className='minted-section'>
									<img className='vector' src={LeftVector} />
									<div className='mid'>
										<img src={Unreveal} />
										{txHashedToken ? <h3> NEARBOT #{txHashedToken}</h3> : <h3> NEARBOT</h3>}
									</div>
									<img className='vector' src={RightVector} />
								</div>
								<p style={{ marginTop: 0 }}>Your Primal Nearbot has now been SACFRICED! The NFT will now be burned 🔥 .</p>
								<p>Share your Classic Nearbot in the TwitterVerse</p>
								<Share url=' ' options={{
									size: 'large',
									text: `The @NearFutureNFT has EVOLVED! 🔥

I just participated their NFC Mint x Burn event to get my unrevealed Classic Nearbot $NEAR NFT 👀

Got to gain Mayka's trust and complete his in-game discord quest to reveal it 

Play now 👉 https://discord.gg/6KdKKUuj7Y
pic.twitter.com/pz395tseuL`,
								}} />
							</>
						}
						<div className="promptBtnSection">
							<button className="modal-close" onClick={handleCallbackModalClose}> ✕  </button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	)
}