import * as nearAPI from 'near-api-js';
import { useState, useEffect } from 'react';
import { Share } from 'react-twitter-widgets'
import { Button, Box, Modal,Grid } from '@mui/material';
import { useWalletSelector } from '../contexts/WalletSelectorContext';
import { NEAR_CONFIG, MINT_PRICE_RED, MINT_PRICE_GOLD } from '../constants';
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import BurnBG from '../assets/nft_bg.png'
import RedCap from '../assets/redcap.png'
import RedCapGif from '../assets/red.gif'
import GoldCap from '../assets/goldcap.png'
import Twitter from '../assets/twitter.png'
import Discord from '../assets/discord.png'
import ModalBg from '../assets/modal_bg.png'
import Unreveal from '../assets/unreveal.jpg'
import GoldCapGif from '../assets/golden.gif'
import LeftVector from '../assets/left_vector.png'
import RightVector from '../assets/right_vector.png'
window.Buffer = window.Buffer || require("buffer").Buffer;  // To fix buffer problem



export default function Mint() {
	const [nfcToken, setNfcToken] = useState([]);
	const [mintCount, setMintCount] = useState(1);
	const { selector, accountId, modal } = useWalletSelector();
	const [goldenCapzTokenId, setGoldenCapzTokenId] = useState(null)
	const BOATLOAD_OF_GAS = nearAPI.utils.format.parseNearAmount("0.0000000003");
	const navigate = useNavigate();


	// Callback modal const
	const [callbackModal, setCallbackModal] = useState(false);
	const [callbackModalMsg, setCallbackModalMsg] = useState(null);
	const handleCallbackModalClose = () => setCallbackModal(false);
	const [txHashedToken, setTxHashedToken] = useState(null);
	const [refundedCount, setRefundedCount] = useState(0);

	const [searchParams, setSearchParams] = useSearchParams();

	// Handle callback function 
	useEffect(() => {

		async function showModal(txHash) {
			setCallbackModal(true);
			console.log('wokring')
			const provider = new nearAPI.providers.JsonRpcProvider(NEAR_CONFIG.nodeUrl);
			console.log(txHash)
			const result = await provider.txStatus(txHash, accountId ?? 'someone1239574.near');
			console.log(result)
			const log = result.receipts_outcome[3].outcome.logs;
			const refundLog = result.receipts_outcome[3].outcome.logs[result.receipts_outcome[3].outcome.logs.length - 1]

			console.log(result.receipts_outcome[3])

			// Handle Result Display
			if (log.length <= 3) {
				const token = log[1].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1];
				if (token) { setTxHashedToken(token); }
			}
			else if (log.length <= 5) {
				const token = [];
				token.push(log[1].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1])
				token.push(log[3].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1])
				if (token) { setTxHashedToken(token); }
			}
			else {
				const token = [];
				token.push(log[1].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1])
				token.push(log[3].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1])
				token.push(log[5].match(new RegExp('token_ids":\\["' + "(.*)" + '"\\]'))[1])
				if (token) { setTxHashedToken(token); }
			}

			// Handle Refund Display
			const isRefunded = refundLog.match(new RegExp('refunds: (.*)'))[0]
			if (isRefunded.length > 0) {
				const refundedCount = isRefunded.substring(isRefunded.length - 1)
				setRefundedCount(refundedCount)
			}
		}
		// Function to check if minted
		async function checkResponse() {
			if (searchParams.get('errorMessage')) {
				setCallbackModal(true);
				setCallbackModalMsg(searchParams.get('errorMessage'));
				navigate('/mint')
			}

			if (searchParams.get("transactionHashes")) {
				const txHash = searchParams.get("transactionHashes")
				const currentMinted = JSON.parse(localStorage.getItem('minted_tx_hashes'));

				if (Array.isArray(currentMinted)) {
					// We never displayed modal for this, now display it and add it into "already displayed list"
					if (currentMinted.indexOf(txHash) === -1) {
						localStorage.setItem('minted_tx_hashes', JSON.stringify([...currentMinted, txHash]));
						showModal(txHash)
					}
				}
				else {
					localStorage.setItem('minted_tx_hashes', JSON.stringify([txHash]));
					showModal(txHash)
				}
			}
		}
		checkResponse();
	}, [searchParams, accountId]);

	// Modal config
	const [promptModal, setPromptModal] = useState(false);
	const promptModalOpen = (token_id) => {
		setGoldenCapzTokenId(token_id);
		setPromptModal(true)
	};
	const promptModalClose = () => {
		setGoldenCapzTokenId(null);
		setPromptModal(false)
	};

	// Get user Bottle Capz
	useEffect(() => {
		async function getAccountState() {
			const near = new nearAPI.Near(NEAR_CONFIG);
			const contractAccount = new nearAPI.Account(near.connection, NEAR_CONFIG.contractName);

			async function getNFT() {
				const response = await contractAccount.viewFunction(NEAR_CONFIG.capzContract, 'nft_tokens_for_owner', { "account_id": accountId });
				setNfcToken(response);
			}

			console.log(selector?.store?.getState()?.contract?.contractId)
			if (selector?.store?.getState()?.contract?.contractId === NEAR_CONFIG.nfcContract && selector.isSignedIn()) {
				getNFT();
			} else {
				setNfcToken([]);
				const wallet = await selector?.wallet()
				wallet.signOut()
			}
		}

		getAccountState()
	}, [selector, accountId]);

	// Functino to mint red cap
	const mintByBurningRedCapz = async (token_id) => {
		const wallet = await selector.wallet();
		return wallet.signAndSendTransaction({
			signerId: accountId,
			actions: [
				{
					type: "FunctionCall",
					params: {
						methodName: "nft_mint",
						args: { mint_type: 2, token_id: token_id },
						gas: BOATLOAD_OF_GAS,
						deposit: nearAPI.utils.format.parseNearAmount(MINT_PRICE_RED),
					},
				},
			],
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	// Function to mint gold cap
	const mintByBurningGoldenCapz = async () => {
		const wallet = await selector.wallet();
		const depositAmount = (parseFloat(MINT_PRICE_GOLD) * mintCount).toString();
		return wallet.signAndSendTransaction({
			signerId: accountId,
			actions: [
				{
					type: "FunctionCall",
					params: {
						methodName: "nft_mint",
						args: { mint_type: 2, token_id: goldenCapzTokenId, quantity: parseInt(mintCount) },
						gas: BOATLOAD_OF_GAS,
						deposit: nearAPI.utils.format.parseNearAmount(depositAmount),
					},
				},
			],
		}).catch((err) => {
			console.log(err);
			throw err;
		});
	}

	// Function to update mint count for gold cap 
	const updateMintCount = (count) => {
		let c = mintCount + count;
		if (c <= 0) c = 1;
		if (c >= 3) c = 3;
		setMintCount(c);
	}

	return (
		<>
			<div id='burnBgSection' style={{ background: "url(" + BurnBG + ")", padding: '2em', height: 'auto' }} className='page' >
				<div>
					<div id='burnBgContent' className='m'>
						<h1> MINT <br /> NEAR FUTURE <span> CLASSIC </span> </h1>

						{
							selector.isSignedIn() ?
								(<p> <img src={GoldCap} /> <b>Golden CAPZ Mints </b> have a 25% Chance of a <b>FREE Mint!</b></p>) :
								null
						}
						<div id='burnItemSection'>
							{
								nfcToken.map((item, index) => {
									return item.token_id.includes('red') ?
										(
											<div className='burnItem r' key={index}>
												<img src={RedCapGif} />
												<button onClick={() => mintByBurningRedCapz(item.token_id)}>
													<img src={RedCap} />
													Mint 1 NFC for 10N
												</button>
											</div>
										) :
										(
											<div className='burnItem g' key={index}>
												<img src={GoldCapGif} />
												<button onClick={() => promptModalOpen(item.token_id)}>
													<img src={GoldCap} />
													Mint 3 NFC for 7N
												</button>
											</div>
										)
								})
							}
							{
								selector.isSignedIn() ? null :
									<Box sx={{ p: 5 }}>
										<h4 id='signInText'>Please sign in with your wallet first</h4>
										<Button variant="outlined" id='topBtn' className={accountId ? 'signoutBtn' : ''} sx={{ px: 4, py: 1 }}
											onClick={() => { modal.show() }}
										>
											<h3 style={{ cursor: 'pointer' }}>{accountId ?? null}</h3>
											<label style={{ cursor: 'pointer' }}> {selector.isSignedIn() ? 'Sign Out' : 'Connect Wallet'} </label>
										</Button>
									</Box>
							}
						</div>
					</div>
				</div>
			</div>
            <div id='footer'>
                <h3> NEAR FUTURE <span> CLASSIC </span> </h3>
                <div>
                    <a className='btm-link' href='https://twitter.com/NearFutureNFT' target="_blank"><img src={Twitter} /> </a>
                    <a className='btm-link' href='https://discord.gg/6KdKKUuj7Y' target="_blank"> <img src={Discord} /></a>
                </div>
            </div>
			<Modal className='prompt-modal' open={promptModal} onClose={promptModalClose} >
				<Box className='modal-box'>
					<img src={ModalBg} className='modal-bg' />
					<div id='mintPromptSection'>
						<button className={mintCount == 1 ? ' hide' : 'mint-count minus'} onClick={() => updateMintCount(-1)}> - </button>
						{(() => {
							const rows = [];
							for (let i = 0; i < mintCount; i++) {
								rows.push(<img src={GoldCap} />);
							}
							return rows;
						})()}
						<button className={mintCount == 3 ? ' hide' : 'mint-count plus'} onClick={() => updateMintCount(1)}> + </button>
					</div>
					<h3> MINTING {mintCount} NEAR FUTURE <span>CLASSIC </span> ?</h3>
					<div className='modal-btn'>
						<button className='no-btn' onClick={promptModalClose}> NO </button>
						<button className='yes-btn' onClick={mintByBurningGoldenCapz}> YES </button>
						<p> Mint up to 3 Classic Nearbots by clicking the "+" icon to add more </p>
					</div>
					<button className="modal-close" onClick={promptModalClose}> ✕  </button>
				</Box>
			</Modal>
			<Modal className={callbackModalMsg ? 'callback-modal' : Array.isArray(txHashedToken) ? 'callback-modal xl' : 'callback-modal lg'} open={callbackModal} onClose={handleCallbackModalClose} >
				<Box className='modal-box'>
					<img src={ModalBg} className='modal-bg' />
					<div style={{ textAlign: "center" }}>
						{callbackModalMsg ?
							<p className="font20">{decodeURIComponent(callbackModalMsg)} </p>
							:
							<>
								<h1> here's your <br /> Near Future <span> Classic </span> </h1>
								<div className='minted-section'>
									<img className='vector' src={LeftVector} />
									{
										Array.isArray(txHashedToken) ?
											<>
												{
													txHashedToken.map((item, index) =>
													(
														<div className='mid' key={index}>
															<img src={Unreveal} />
															{txHashedToken ? <h3> NEARBOT #{item}</h3> : <h3> NEARBOT</h3>}
															{refundedCount > index ? (
																<Grid container spacing={2} style={{padding:'0em 3em'}}>
																	<Grid item xs={4}>
																		<img src={GoldCap} style={{ width: '3em' }} />
																	</Grid>
																	<Grid item xs={8}>
																		<h3 style={{ color: "green" }}>Refunded</h3>
																	</Grid>
																</Grid>
															) : null}
														</div>
													)
													)
												}
											</>
											:
											<>
												<div className='mid'>
													<img src={Unreveal} />
													{txHashedToken ? <h3> NEARBOT #{txHashedToken}</h3> : <h3> NEARBOT</h3>}
												</div>
											</>
									}

									<img className='vector' src={RightVector} />
								</div>
								<p> Share your Classic Nearbot in the TwitterVerse</p>
								
								<Share url=' ' options={{
									size: 'large',
									text: `The @NearFutureNFT has EVOLVED! 🔥

I just participated their NFC Mint x Burn event to get my unrevealed Classic Nearbot $NEAR NFT 👀

Got to gain Mayka's trust and complete his in-game discord quest to reveal it 

Play now 👉 https://discord.gg/6KdKKUuj7Y
pic.twitter.com/pz395tseuL`,
								}} />
							</>
						}
						<div className="promptBtnSection">
							<button className="modal-close" onClick={handleCallbackModalClose}> ✕  </button>
						</div>
					</div>
				</Box>
			</Modal>
		</>
	)
}