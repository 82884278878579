import React, { useCallback, useContext, useEffect, useState } from "react";
import { map, distinctUntilChanged } from "rxjs";
import { setupWalletSelector } from "@near-wallet-selector/core";
// import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupModal } from "@winrey/near-wallet-selector-modal-ui";
import { NEAR_CONFIG } from "../constants";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import { useLocation } from 'react-router-dom';
import { setupMeteor } from "@winrey/meteorwallet-near-selector";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";


const WalletSelectorContext = React.createContext(null);

export const WalletSelectorContextProvider = ({ children }) => {
	const [selector, setSelector] = useState(null);
	const [modal, setModal] = useState(null);
	const [accounts, setAccounts] = useState([]);
	const [contractId, setContractId] = useState(null);
	const location = useLocation();

	const init = useCallback(async () => {

    const setupNearWalletFake = () => async (...options) => {
      const result = await setupNearWallet()(...options)
      result.metadata.deprecated = false;
      return result
    }

		const _selector = await setupWalletSelector({
			network: NEAR_CONFIG.networkId,
			debug: false,
			modules: [
        setupNearWalletFake()
			],
		});
    const contractId = location.pathname == '/burn' ? NEAR_CONFIG.nfpContract : NEAR_CONFIG.nfcContract
		console.log({contractId})
		const _modal = setupModal(_selector, { contractId });
		const state = _selector.store.getState();

		setAccounts(state.accounts);

		window.selector = _selector;
		window.modal = _modal;

		setSelector(_selector);
		setModal(_modal);
	}, [location.pathname]);

	useEffect(() => {
		init().catch((err) => {
			console.error(err);
			alert("Failed to initialise wallet selector");
		});
	}, [init]);

	useEffect(() => {
		if (!selector) {
			return;
		}

		const subscription = selector.store.observable
			.pipe(
				map((state) => state.accounts),
				distinctUntilChanged()
			)
			.subscribe((nextAccounts) => {
				console.log("Accounts Update", nextAccounts);

				setAccounts(nextAccounts);
			});

		return () => subscription.unsubscribe();
	}, [selector]);

	if (!selector || !modal) {
		return null;
	}

	const accountId =
		accounts.find((account) => account.active)?.accountId
		|| accounts?.[0]?.accountId
		|| null;

	return (
		<WalletSelectorContext.Provider
			value={{
				selector,
				modal,
				accounts,
				accountId,
			}}
		>
			{children}
		</WalletSelectorContext.Provider>
	);
};

export function useWalletSelector() {
	const context = useContext(WalletSelectorContext);

	if (!context) {
		throw new Error(
			"useWalletSelector must be used within a WalletSelectorContextProvider"
		);
	}

	return context;
}