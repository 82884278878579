import { AppBar, Container, Toolbar, Box, Button } from '@mui/material';
import { useWalletSelector } from '../contexts/WalletSelectorContext';
import "@near-wallet-selector/modal-ui/styles.css";

import ImgLogo from '../assets/logo.png'
import Tinker from '../assets/tinker.png'
import Twitter from '../assets/twitter.png'
import Discord from '../assets/discord.png'
import { useLocation,Link } from 'react-router-dom';

export default function TopNavBar() {

	const location = useLocation()
	const { selector, modal, accounts, accountId } = useWalletSelector();

	const connectWalletHandler = async () => {
		if (selector.isSignedIn()) {
			const wallet = await selector.wallet()
			return wallet.signOut()
		}
		return modal.show()
	}

	return (
		<AppBar id='topNav' position="static" sx={{ p: 2 }} color="transparent" elevation={0}>
			<Container maxWidth="xl">
				<Toolbar disableGutters >
					<Link to='/' id='topLogoSection'>
						<img src={ImgLogo} className='topLogo' alt="NFC logo" /> <b>X</b> < img className='topLogo' src={Tinker} />
					</Link>
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
						<a className='top-link' href='https://twitter.com/NearFutureNFT' target="_blank"><img src={Twitter} /> </a>
						<a className='top-link' href='https://discord.gg/6KdKKUuj7Y' target="_blank"> <img src={Discord} /></a>
						{
							location.pathname == '/' ? null :
								<Button variant="outlined" id='topBtn' className={accountId ? 'signoutBtn' : ''} sx={{ px: 4, py: 1 }} onClick={connectWalletHandler}>
									<h3 style={{cursor:'pointer'}}>{accountId ?? null}</h3>
									<label style={{cursor:'pointer'}}> {selector.isSignedIn() ? 'Sign Out' : 'Connect Wallet'} </label>
								</Button>
						}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	)
}
